import classNames from 'classnames';
import Image from 'next/legacy/image';
import {ComponentPropsWithoutRef} from 'react';

import Col from '../../common/Col';
import Row from '../../common/Row';
import {useBreakpoint} from '../../common/util/useBreakpoint';
import {ApiNewsletterNewsletter} from '../../strapi/generated/contentTypes';
import {ProcessedValues} from '../../strapi/types';
import Button from './buttons/Button';

type NewsletterProps = ComponentPropsWithoutRef<'div'> & {
  data: ProcessedValues<ApiNewsletterNewsletter>;
};

const Newsletter = ({className, data, ...props}: NewsletterProps) => {
  const {isMobile} = useBreakpoint('mobile');
  return (
    <Row className={classNames('justify-center space-x-24 items-center p-24 bg-primary-600', className)} {...props}>
      {!isMobile && <Image src="/img/newsletter-image.svg" height={149} width={427} alt="Newsletter image" />}
      <Col className="space-y-6 text-white">
        <div className="text-3xl font-semibold leading-none">{data.title}</div>
        <div className="text-lg leading-tight">{data.subtitle}</div>
        <Button
          className="w-fit !bg-primary-600 !border-white !text-white"
          variant="secondary"
          target="_blank"
          href={data.url}
        >
          {data.button_text}
        </Button>
      </Col>
    </Row>
  );
};

export default Newsletter;
