import classNames from 'classnames';
import Image from 'next/legacy/image';
import Link from 'next/link';
import React, {ComponentPropsWithoutRef, useMemo} from 'react';

import {getDraftResourceURL, getResourceURL} from '../../../urls';
import Col from '../../common/Col';
import Row from '../../common/Row';
import {ApiResourceResource} from '../../strapi/generated/contentTypes';
import {ProcessedValues} from '../../strapi/types';
import {setLatestResourcesUrl} from '../util/resourcesPageTracking';

export type ResourceCardProps = ComponentPropsWithoutRef<'div'> & {
  data: Pick<ProcessedValues<ApiResourceResource>, 'slug' | 'title' | 'image' | 'category' | 'author' | 'date'>;
  draft?: boolean;
};

const ResourceCard = ({className, data, draft, ...props}: ResourceCardProps) => {
  const resourceUrl = useMemo(
    () => (draft ? getDraftResourceURL(data.slug) : getResourceURL(data.slug)),
    [data.slug, draft]
  );
  return (
    <Link href={resourceUrl} passHref legacyBehavior>
      <a
        onClick={setLatestResourcesUrl}
        className={classNames('text-start !text-primary-800 hover:!no-text-shadow cursor-pointer group', className)}
      >
        <Col {...props}>
          <div className={classNames('relative h-96 bg-primary-700 rounded-3xl overflow-hidden')}>
            {data.image?.[0] && (
              <Image
                className="transition-transform duration-500 group-hover:scale-125"
                src={data.image?.[0].src}
                alt={data.image?.[0].alt}
                // Set a size smaller than 100% to optimize the image load performance
                sizes="90rem"
                layout="fill"
                objectFit="cover"
              />
            )}
            {data.category && (
              <div className="absolute left-8 bottom-8 text-xs bg-primary-300 rounded-full py-0.5 px-8 font-medium">
                {data.category.name}
              </div>
            )}
          </div>
          <div className="text-lg font-semibold mt-6 group-hover:text-primary-500">{data.title}</div>
          <Row className="text-sm mt-3 space-x-3">
            <div className={'whitespace-nowrap'}>{data.date}</div>
            {data.author && (
              <>
                <div>|</div>
                <div className="text-primary-600 font-semibold">{data.author.name}</div>
              </>
            )}
          </Row>
        </Col>
      </a>
    </Link>
  );
};

export default ResourceCard;
