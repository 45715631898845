import {InferGetStaticPropsType} from 'next';
import Image from 'next/image';

import Col from '../features/common/Col';
import {useEnvironment} from '../features/common/EnvironmentProvider';
import Row from '../features/common/Row';
import useCurrentUser, {hasFilledStartFlow} from '../features/query/useCurrentUser';
import {getCmsEntry, getCmsList} from '../features/strapi';
import {ApiFooterFooter, ApiHomepageHomepage, ApiResourceResource} from '../features/strapi/generated/contentTypes';
import Button from '../features/tool/components/buttons/Button';
import Footer from '../features/tool/components/Footer';
import GenericCard from '../features/tool/components/GenericCard';
import InfoCard from '../features/tool/components/InfoCard';
import {getToolLayout} from '../features/tool/components/Layout';
import Newsletter from '../features/tool/components/Newsletter';
import ResourceCard from '../features/tool/components/ResourceCard';
import RichText from '../features/tool/components/RichText';
import SectionWithImage from '../features/tool/components/SectionWithImage';
import icons, {IconId} from '../features/tool/icons';
import {buildStaticPropsResponse} from '../features/tool/util/dataFetch';
import {Plant5LeavesIcon} from '../resources/Icons';
import URLS, {getResourcesURL} from '../urls';
import {NextPageWithLayout} from './_app';

const HomePage: NextPageWithLayout<InferGetStaticPropsType<typeof getStaticProps>> = ({
  homepage,
  resources,
  footer,
}) => {
  const environment = useEnvironment();
  const currentUser = useCurrentUser();
  const getStartedUrl = hasFilledStartFlow(currentUser) ? URLS.dashboard : URLS.start;

  const hero_image = homepage.hero_image?.[0];
  const supplier_image = homepage.supplier_image?.[0];
  const game_image = homepage.game_image?.[0];

  return (
    <div className={'w-full'}>
      <Col className="max-w-wider mx-auto py-2xl mobile:py-lg px-sm gap-[12rem] mobile:gap-[10rem] overflow-hidden">
        <SectionWithImage
          title={homepage.hero_title}
          description={homepage.hero_description}
          image={
            hero_image ? (
              <Image
                src={hero_image.src}
                width={hero_image.width}
                height={hero_image.height}
                className="object-contain__"
                alt={'Decision making'}
              />
            ) : undefined
          }
          buttonLabel={'Get Started'}
          buttonHref={getStartedUrl}
        />
        <SectionWithImage
          reversed
          title={homepage.supplier_title}
          description={homepage.supplier_description}
          image={
            supplier_image ? (
              <Image
                src={supplier_image.src}
                width={supplier_image.width}
                height={supplier_image.height}
                className="object-contain"
                alt={'Supplier map'}
              />
            ) : undefined
          }
          buttonLabel={'Explore map'}
          buttonHref={URLS.marketPlace.suppliers}
          extra={
            <GenericCard border={'dark'} className={'mt-lg p-xs !flex-row items-center gap-xs flex-wrap'}>
              <Row className={'grow items-start font-semibold gap-4'}>
                <Plant5LeavesIcon className={'flex-none mt-2'} />
                {homepage.supplier_action}
              </Row>
              <Row className={'grow justify-end'}>
                <Button
                  variant={'primary'}
                  accessory={'forward'}
                  href={URLS.about.becomeSupplier}
                  className={'flex-none whitespace-nowrap mobile:self-end'}
                >
                  Find out more
                </Button>
              </Row>
            </GenericCard>
          }
        />
        <Col>
          <Col className="flex-1 max-w-[62rem] mt-20">
            <div className="relative">
              <h1 className="mb-xs text-primary-800 text-huge font-semibold leading-tight">
                {homepage.features_title}
              </h1>
              <div className="absolute right-0 -top-20 rounded-full w-[19rem] h-[19rem] bg-primary-100 -z-10"></div>
            </div>
            <RichText className="text-lg" content={homepage.features_description} />
          </Col>
          <Button
            className="block mobile:hidden w-fit self-end"
            variant="secondary"
            accessory="forward"
            href={URLS.about.index}
          >
            Read more
          </Button>
          <Row className="flex-wrap mobile:flex-nowrap mobile:overflow-x-scroll gap-sm mt-md mobile:-mx-md mobile:px-md">
            {homepage.feature_cards?.map((fc, i) => (
              <InfoCard
                key={`feature_card_${i}`}
                className="flex-1 min-w-[200px] mobile:flex-none mobile:w-10/12 mobile:flex-shrink-0"
                icon={icons[fc.icon_id as IconId]}
                label={fc.text}
                dark={fc.dark}
                border
              />
            ))}
          </Row>
          <Button className="hidden mobile:block w-fit mt-md" variant="secondary" accessory="forward">
            Read more
          </Button>
        </Col>
        <SectionWithImage
          title={homepage.game_title}
          description={homepage.game_description}
          image={
            game_image ? (
              <Image
                src={game_image.src}
                width={game_image.width}
                height={game_image.height}
                className="object-contain"
                alt="Play game"
              />
            ) : undefined
          }
          buttonLabel={'Play'}
          buttonHref={URLS.game}
        />
        <Col className="mb-lg">
          <Row className="w-full items-center justify-between mb-lg">
            <h1 className="text-primary-800 text-huge font-semibold leading-tight">{homepage.resources_title}</h1>
            <Button
              className="block mobile:hidden w-fit h-fit"
              href={getResourcesURL()}
              variant="secondary"
              accessory="forward"
            >
              See all
            </Button>
          </Row>
          <Row className="flex-nowrap mobile:overflow-x-scroll gap-sm mobile:-mx-md mobile:px-md">
            {resources.map((rc, i) => (
              <ResourceCard
                key={`resources_card_${i}`}
                className="flex-1 mobile:flex-none mobile:w-10/12 mobile:flex-shrink-0"
                data={rc}
              />
            ))}
          </Row>
        </Col>
      </Col>
      {environment && <Newsletter data={environment.newsletter} />}
      <Footer data={footer} />
    </div>
  );
};

export const getStaticProps = async () => {
  return buildStaticPropsResponse({
    ...(await getCmsEntry<ApiHomepageHomepage, 'homepage'>({
      path: 'homepage',
      params: {populate: '*'}, // Use populate when there are nested components
      processDataMap: {
        hero_description: 'markdown',
        features_description: 'markdown',
        game_description: 'markdown',
        hero_image: 'image',
        supplier_image: 'image',
        game_image: 'image',
      },
    })),
    ...(await getCmsList<ApiResourceResource, 'resources'>({
      path: 'resources',
      params: {populate: ['category', 'image', 'author'], pagination: {page: 1, pageSize: 3}, sort: ['id:desc']},
      processDataMap: {image: 'image', category: 'commonChild', author: 'commonChild', date: 'date'},
    })),
    ...(await getCmsEntry<ApiFooterFooter, 'footer'>({path: 'footer'})),
  });
};

export default HomePage;

HomePage.getLayout = getToolLayout;

HomePage.title = 'Home';
