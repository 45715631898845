import classNames from 'classnames';
import React, {PropsWithChildren, ReactElement} from 'react';

import Col from '../../common/Col';

export type Props = PropsWithChildren<{
  className?: string;
  border?: 'light' | 'dark' | 'green' | 'darkgreen' | 'none';
  header?: ReactElement;
  footer?: ReactElement;
}>;

const GenericCard = (props: Props) => {
  const {className, border = 'none', header, footer, children} = props;

  return (
    <Col
      className={classNames(
        'rounded-3xl overflow-hidden',
        {
          'border-thin border-gray-100': border === 'light',
          'border-thin border-primary-700': border === 'dark',
          'border-thin border-primary-300': border === 'green',
          'border-thin border-primary-500': border === 'darkgreen',
        },
        className
      )}
    >
      {header && <div className={'border-b-thin border-gray-100'}>{header}</div>}
      {children}
      {footer}
    </Col>
  );
};

export default GenericCard;
