import classNames from 'classnames';
import React, {ReactElement} from 'react';

import Col from '../../common/Col';
import Button from './buttons/Button';
import RichText from './RichText';

type Props = {
  title: string;
  description: string;
  image: ReactElement | undefined;
  buttonLabel: string;
  buttonHref: string;
  reversed?: boolean;
  dark?: boolean;
  newTab?: boolean;
  extra?: ReactElement;
  textSize?: 'small' | 'large';
  className?: string;
};

const SectionWithImage = ({
  title,
  description,
  image,
  buttonLabel,
  buttonHref,
  reversed = false,
  dark = false,
  newTab = false,
  extra,
  textSize = 'large',
  className,
}: Props) => {
  return (
    <div
      className={classNames(
        'flex mobile:flex-col-reverse items-center gap-xl mobile:gap-md',
        reversed ? 'flex-row-reverse' : 'flex-row',
        dark && 'bg-primary-700 text-white',
        className
      )}
    >
      <Col className="flex-1 gap-xs">
        <h1 className={classNames('font-semibold leading-tight', textSize === 'large' ? 'text-huge' : 'text-3xl')}>
          {title}
        </h1>
        <RichText className={classNames(textSize === 'large' && 'text-lg')} content={description} />
        <Button
          className={classNames('w-fit mt-sm px-sm mobile:mt-md', dark && 'border-white')}
          variant="primary"
          href={buttonHref}
          target={newTab ? '_blank' : undefined}
          rel={newTab ? 'noopener noreferrer' : undefined}
        >
          {buttonLabel}
        </Button>
        {extra}
      </Col>
      {image && <div className="relative w-2/5 mobile:w-full">{image}</div>}
    </div>
  );
};

export default SectionWithImage;
