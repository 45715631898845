import classNames from 'classnames';
import React, {ReactElement} from 'react';

import Col from '../../common/Col';
import Row from '../../common/Row';
import GenericCard from './GenericCard';

export type InfoCardProps = {
  className?: string;
  icon: ReactElement;
  label: string;
  dark?: boolean;
  border?: boolean;
};

const InfoCard = (props: InfoCardProps) => {
  const {className, icon, label, dark, border = false} = props;

  const IconElement = React.cloneElement(icon, {className: classNames(icon.props.className, 'w-[7rem] h-[7rem]')});

  return (
    <GenericCard
      border={border ? 'light' : 'none'}
      className={classNames(dark ? '!bg-primary-700 text-white' : 'bg-white', className)}
    >
      <Col className={'grow p-md mobile:p-xs'}>
        {IconElement}
        <Row className={'grow text-base font-semibold mt-xs place-items-center'}>{label}</Row>
      </Col>
    </GenericCard>
  );
};

export default InfoCard;
