import Image from 'next/legacy/image';
import Link from 'next/link';
import React, {ReactElement} from 'react';

import {FacebookIcon, InstagramIcon, LinkedInIcon, TwitterIcon} from '../../../resources/Icons';
import envirocropsWhite from '../../../resources/img/envirocropsWhite.svg';
import URLS, {getResourcesURL} from '../../../urls';
import Col from '../../common/Col';
import Row from '../../common/Row';
import {ApiFooterFooter} from '../../strapi/generated/contentTypes';
import {ProcessedValues} from '../../strapi/types';

type Props = {
  data: ProcessedValues<ApiFooterFooter>;
};

const Footer = ({data}: Props): ReactElement => {
  return (
    <div className="bg-primary-700 text-white [&_a]:!no-text-shadow">
      <Row className={'px-2xl mobile:px-md pt-lg mb-xs'}>
        <Image src={envirocropsWhite} alt={'Envirocrops logo'} layout={'intrinsic'} />
      </Row>
      <Row className="justify-between items-end border-gray-200 border-b px-2xl mobile:px-md pb-lg text-xs">
        <Col>
          <label className="mb-[1rem]">@Envirocrops</label>
          <Link href={`mailto:${data.email}`} className="no-underline hover:text-primary-500 text-white mb-[1rem]">
            {data.email}
          </Link>
          <Row className="space-x-xs">
            <Link href={data.linkedin} target="_blank" rel="noopener noreferrer">
              <LinkedInIcon className="h-[14px] w-[14px] fill-white hover:fill-primary-500" />
            </Link>
            <Link href={data.facebook} target="_blank" rel="noopener noreferrer">
              <FacebookIcon className="h-[14px] w-[14px] fill-white hover:fill-primary-500" />
            </Link>
            <Link href={data.instagram} target="_blank" rel="noopener noreferrer">
              <InstagramIcon className="h-[14px] w-[14px] fill-white hover:fill-primary-500" />
            </Link>
            <Link href={data.twitter} target="_blank" rel="noopener noreferrer">
              <TwitterIcon className="h-[14px] w-[14px] fill-white hover:fill-primary-500" />
            </Link>
          </Row>
        </Col>
        <Col className="text-right">
          <Link href={URLS.about.index} className="no-underline hover:text-primary-500 text-white">
            About
          </Link>
          <Link href={getResourcesURL()} className="no-underline hover:text-primary-500 text-white">
            Resources
          </Link>
          <Link href={URLS.game} className="no-underline hover:text-primary-500 text-white">
            STEM Game
          </Link>
          <Link href={URLS.contact} className="no-underline hover:text-primary-500 text-white">
            Contact us
          </Link>
        </Col>
      </Row>
      <Row className="items-center px-2xl mobile:px-md py-xs gap-x-lg gap-y-xs flex-wrap text-2xs">
        <label>@2022 Envirocrops</label>
        <Link
          href={data.privacyPolicyLink}
          className="no-underline hover:text-primary-500 text-white"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy, Terms & Conditions
        </Link>
        <Link href={URLS.siteMap} className="no-underline hover:text-primary-500 text-white">
          Site map
        </Link>
      </Row>
    </div>
  );
};

export default Footer;
